.avatar-grid {
  display: grid;
  grid-template-columns: auto auto;
  max-width: 15%;
  min-width: 5%;
  grid-auto-columns: 100px;
}

.box1 {
  grid-row-start: 1;
  grid-row-end: 3;
}
