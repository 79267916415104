.BookItem {
  color: #4b4b4b;
  max-width: 180px;
  margin: 2%;
  font-size: 18px;

}
.BookItem-cover {
  cursor: pointer;
  width: 180px;
  height: 270px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  /* border: #43a747 5px solid; */
}

.BookItem-body {
  transition: all 0.15s ease-in-out;
}
.BookItem-body:hover {
  transform: scale(1.03);
}
.BookItem-mark {
  margin-top: -1px;
  margin-left: 160px;
}
.title-name {
  color: #4b4b4b;
}
.author-name {
  color: #4b4b4b;
  opacity: 50%;
  margin-top: -5px;
}

