.NavBar{
    position: sticky;  top: 0; 
    z-index: 1000;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    text-align:center;
    text-justify: space-between;
    word-wrap: wrap;
    padding:1.5rem;
    background-color:#020b16;
    color:white;
}
