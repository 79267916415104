.like-icon{
width: 20px;
 float:right; 

}
.like-count{
    padding-left: 0.5%;
    float:right; 
    font-size:100%;
    /* vertical-align:text-top; */
    line-height: 100%;

}
.like-container{
    display: inline;
}